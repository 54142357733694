import React from 'react';
import { PictureContainer, SecondaryBanner as Banner, Section } from '@belong/ui-components';

import { ISecondaryBannerProps } from './model';

export const SectionSecondaryBanner: React.FC<ISecondaryBannerProps> = ({
  id,
  identifier,
  image,
  layout,
  sectionStyles,
  ...otherProps
}: ISecondaryBannerProps) => {
  const imageCropSide = layout === 'contentRight' ? 'top_right' : 'top_left';

  const BannerImage = (
    <PictureContainer
      src={{
        xs: [image.src, { w: 450, h: 250, fit: 'fill', f: 'face' }],
        sm: [image.src, { w: 800, h: 250, fit: 'fill', f: 'face' }],
        md: [image.src, { w: 1030, h: 500, fit: 'crop', f: imageCropSide }],
        lg: [image.src, { w: 1440, h: 700, fit: 'fill', f: 'face' }],
        xl: [image.src, { w: 2000, h: 700, fit: 'fill', f: 'face' }]
      }}
      alt={image.alt}
      contentType={image.contentType}
    />
  );

  return (
    <Section
      data-identifier={identifier}
      data-contentid={id}
      data-testid="section-secondary-banner"
      isFullWidth
      isPadded={false}
      {...sectionStyles}
    >
      <Banner {...otherProps} layout={layout} image={BannerImage} />
    </Section>
  );
};

export default SectionSecondaryBanner;
