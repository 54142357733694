import { media, mediaMap } from '@belong/themes';
import styled, { css } from 'styled-components';
import Container from '../../layout/Container';
import UICard from '../../styles/UICard';

const CONTENT_PADDING = {
  xs: '1.6rem',
  md: '3.2rem',
  xl: '5.6rem'
};

export const OuterWrapper = styled.div`
  position: relative;

  ${media('md')`
    padding: 4.8rem 0 6.4rem;
  `}

  ${media('lg')`
    padding: 7.2rem 0 6.4rem;
  `}

  img {
    width: 100%;
    height: 16.8rem;
    object-fit: cover;

    ${media('md')`
    position: absolute;
    top: 0;
    height: calc(100% - 12.8rem);
    min-height: 43.2rem;
  `}
  }
`;

export const InnerWrapper = styled(Container)`
  position: relative;
  top: -3.2rem;

  ${media('md')`
    top: 6.4rem;
  `}
`;

export const ContentBox = styled(UICard).attrs(() => ({ hasShadow: true }))`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media('md')`
    min-height: 36.8rem;
  `}

  ${mediaMap(
    CONTENT_PADDING,
    (padding: string) => css`
      padding: ${padding};
    `
  )}
`;

export const ButtonWrapper = styled.div`
  margin-top: 1.6rem;

  ${media('md')`
      margin-top: 2.4rem;
  `}
`;
