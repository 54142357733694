import React from 'react';
import { EMPTY_DOCUMENT } from '@contentful/rich-text-types';

import { HEADING_VARIANTS } from '@belong/types';

import { ISecondaryBanner } from './SecondaryBanner.types';
import * as Banner from './SecondaryBanner.styles';
import ContentFormatter from '../../styles/ContentFormatter';
import { Copy, Heading, RichText } from '../../styles/Typography';
import { TEXT_NODES } from '../../styles/Typography/RichText/RichText.types';
import { Button } from '../../actions/Button';
import { Column, Columns, Inline, Stack } from '../../ui';

const SecondaryBanner: React.FC<ISecondaryBanner> = (props: ISecondaryBanner) => {
  const { primaryLink, secondaryLink, content, heading, copy, image, layout } = props;

  return (
    <Banner.OuterWrapper>
      {image}
      <Banner.InnerWrapper>
        <Columns alignX={layout === 'contentRight' ? 'right' : 'left'}>
          <Column width={{ md: '7/12', lg: '1/2' }}>
            <Banner.ContentBox>
              <Stack>
                <ContentFormatter>
                  {heading || copy ? (
                    <>
                      <Heading as="h2" variant={HEADING_VARIANTS.large}>
                        {heading}
                      </Heading>
                      <Copy variant="large">{copy}</Copy>
                    </>
                  ) : (
                    <RichText html={content || EMPTY_DOCUMENT} p={TEXT_NODES.copyLarge} h2={TEXT_NODES.headingLarge} />
                  )}
                </ContentFormatter>
                {(primaryLink || secondaryLink) && (
                  <Inline space="medium">
                    {primaryLink && (
                      <Button
                        {...primaryLink}
                        width={{
                          xs: 'full',
                          sm: 'min'
                        }}
                      >
                        {primaryLink.label}
                      </Button>
                    )}
                    {secondaryLink && (
                      <Button
                        {...secondaryLink}
                        width={{
                          xs: 'full',
                          sm: 'min'
                        }}
                      >
                        {secondaryLink.label}
                      </Button>
                    )}
                  </Inline>
                )}
              </Stack>
            </Banner.ContentBox>
          </Column>
        </Columns>
      </Banner.InnerWrapper>
    </Banner.OuterWrapper>
  );
};

SecondaryBanner.displayName = 'SecondaryBanner';
export default SecondaryBanner;
